<template>
  <v-app id="reset">
    <v-main>
      <v-container fluid fill-height class="backImg text-center">
        <Loader :loading="loading" />
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-card
              dark
              class="animate__animated animate__bounceInRight mx-auto py-6"
              color="primary"
              elevation="5"
              max-width="500"
            >
              <v-card-title
                color="primary"
                class="justify-center main-title"
                v-text="'Restablecer contraseña'"
              />
              <v-card-text>
                <v-form ref="formReset" lazy-validation @submit.prevent>
                  <v-row class="itemsCenter justify-center">
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                      <v-img
                        max-width="200px"
                        class="
                          animate__animated animate__bounceInLeft
                          img-shadow
                        "
                        :src="logo"
                        max-height="130"
                        contain
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <h1 class="white--text">Notaría Pública NO. 51</h1>
                  <br />
                  <h2 class="white--text">Sistema Reloj Checador</h2>
                  <br />
                  <h2 class="white--text">PIN de seguridad</h2>
                  <div class="reset-pin">
                    <PincodeInput
                      v-model="form.pin_seguridad"
                      placeholder="0"
                      length="6"
                    />
                  </div>
                  <br />
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.password"
                        label="Contraseña"
                        prepend-inner-icon="mdi-form-textbox-password"
                        :rules="rules.password"
                        type="password"
                        :disabled="loading || form.pin_seguridad.length != 6"
                        outlined
                        required
                        autocomplete="off"
                        class="form-input"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.password2"
                        label="Confirmar contraseña"
                        prepend-inner-icon="mdi-lock-reset"
                        :rules="rules.password"
                        type="password"
                        :disabled="loading || form.pin_seguridad.length != 6"
                        outlined
                        required
                        autocomplete="off"
                        class="form-input"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row align="center" justify="space-around" class="pa-3">
                  <v-btn large color="grey" :disabled="loading" @click="back()"
                    >Volver</v-btn
                  >
                  <v-btn
                    large
                    color="success"
                    class="white--text"
                    :disabled="
                      loading ||
                      !isValid ||
                      completed ||
                      form.password == '' ||
                      form.password2 == '' ||
                      form.password != form.password2
                    "
                    @click="resetPassword()"
                    >Continuar</v-btn
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <BasicAlert
          :active.sync="alert.active"
          :msg="alert.msg"
          :color="alert.color"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import "animate.css";
import { Loader, BasicAlert } from "@/components";
import PincodeInput from "vue-pincode-input";

export default {
  name: "reset",
  components: {
    Loader,
    BasicAlert,
    PincodeInput,
  },
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    path: "/pub/reset/password",
    logo: require("@/assets/images/logo_c.png"),

    loading: false,
    request: "",
    completed: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    form: {
      password: "",
      pin_seguridad: "",
    },
    rules: {
      password: [
        (v) => !!v || "El campo es requerido",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número",
        (v) =>
          (v && v.length <= 60) || "La contraseña excede la longitud máxima",
        (v) =>
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) ||
          "La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número",
      ],
      pin: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length >= 6) || "El PIN debe contener mínimo 6 números",
      ],
    },
  }),
  mounted() {
    let id = this.$route.path.split("/");
    id = id[id.length - 1];
    let regex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (regex.test(id)) {
      this.request = id;
    } else {
      this.alert.active = true;
      this.alert.color = "error";
      this.alert.msg = "La solicitud es incorrecta";
    }
  },

  computed: {
    isValid: function () {
      if (this.form.password != "" && this.form.password2 != "") {
        return this.$refs.formReset.validate();
      }
      return false;
    },
  },
  methods: {
    clear() {
      this.form.password = "";
      this.form.password2 = "";
    },
    resetPassword() {
      this.loading = true;
      let data = {
        codigo: this.request,
        password: this.form.password,
        pin_seguridad: this.form.pin_seguridad,
      };

      this.axios
        .post(this.host + this.path, { data })
        .then((response) => {
          this.clear();
          this.completed = true;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "La contraseña ha sido actualizada";
          setTimeout(() => {
            this.$router.push("/login");
          }, 2500);
        })
        .catch((error) => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message;
        })
        .finally(() => {});
    },
    back() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.main-title {
  font-size: 28px;
  color: white;
}
.backImg {
  background: url("../assets/images/main.jpeg");
  background-size: cover;
  height: 100vh;
}
.form-input >>> .v-input__slot::after {
  border-color: white !important;
}

.form-input >>> .v-input__slot::before {
  border-color: white !important;
}
.form-input >>> .error--text {
  color: white !important;
}
.form-input >>> input {
  caret-color: white !important;
}
</style>
